/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { searchDeploymentEsResourceLogsUrl } from '@modules/cloud-api/v1/urls'

import { ThunkAction } from '@/types/redux'

import asyncRequest from '../../../../actions/asyncRequests'
import { FETCH_LOGS } from '../../constants/actions'

import type { FetchLogsParams } from '../../reducers/logs/logTypes'

export function fetchLogs(
  deploymentId: string,
  refId: string,
  query: FetchLogsParams,
): ThunkAction {
  if (!query.q) {
    delete query.q
  }

  const url = searchDeploymentEsResourceLogsUrl({
    ...query,
    deploymentId,
    refId,
    filter: query.filter.join(`,`),
  })

  return asyncRequest({
    type: FETCH_LOGS,
    url,
    meta: { deploymentId },
    crumbs: [deploymentId],
    abortIfInProgress: true,
    includeHeaders: true,
  })
}
