/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FETCH_METRICS } from '../../constants/actions'

import type { ReduxState } from '@/types/redux'
import type { Metrics, MetricsPayload, MetricBucket, MetricViz } from './types'

interface Action {
  type: typeof FETCH_METRICS
  error?: boolean
  payload?: MetricsPayload
  meta: {
    regionId: string
    stackDeploymentId: string
    etag?: string
  }
}

type State = {
  [regionAndClusterId: string]: {
    etag: string | undefined
    metrics: Metrics
  }
}

export default function metricsReducer(state: State = {}, action: Action) {
  if (action.type === FETCH_METRICS) {
    if (!action.error && action.payload) {
      const { regionId, stackDeploymentId, etag } = action.meta

      const key = createDescriptor(regionId, stackDeploymentId)
      const oldMetrics = state[key]

      if (oldMetrics != null && oldMetrics.etag !== etag) {
        return state
      }

      return {
        ...state,
        [key]: { etag, metrics: reduceMetrics(action.payload) },
      }
    }
  }

  return state
}

export function getMetrics(
  state: ReduxState,
  regionId: string,
  deploymentId: string,
): Metrics | null {
  const metricsState = state.metrics[createDescriptor(regionId, deploymentId)] // nosemgrep
  return metricsState == null ? null : metricsState.metrics
}

function createDescriptor(regionId: string, deploymentId: string): string {
  return `${regionId}/${deploymentId}`
}

function calculateCpuMetrics(cpuUsagePerNode: MetricBucket[]) {
  const cpuUsage: MetricViz[] = []
  const cpuCredits: MetricViz[] = []

  for (const nodeBucket of cpuUsagePerNode) {
    const nodeName = nodeBucket.key.replace(/-00+/, `-0`)
    const cpuNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const cpuCreditsNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }

    for (const timeBucket of nodeBucket.aggregations.aggregations.per_interval
      .date_histogram_aggregations_result.buckets ?? []) {
      const timestamp = new Date(timeBucket.key)
      const innerTimeBucket = timeBucket.aggregations.aggregations

      const boostFactor = innerTimeBucket?.boost?.min_aggregations_result?.value
      const normalizationFactor =
        // If there's no boost value, it's 1000, i.e. no boosting.
        (boostFactor === 0 || boostFactor == null ? 1000 : boostFactor) / 1000 / 1000

      // normalizationFactor to turn it into a percentage taking boosting into account
      // both boost and the multiplied number are in thousands, so we divide by 1000 twice

      const cpuCreditsMin = innerTimeBucket.cpu_credits?.avg_aggregations_result?.value || 0
      const aggsResult = innerTimeBucket.cpu_usage?.stats_aggregations_result

      cpuNode.x.push(timestamp)
      cpuNode.y.push((aggsResult?.max || 0) * normalizationFactor * 100)

      cpuCreditsNode.x.push(timestamp)
      cpuCreditsNode.y.push(cpuCreditsMin)
    }

    cpuUsage.push(cpuNode)
    cpuCredits.push(cpuCreditsNode)
  }

  return {
    cpuUsage,
    cpuCredits,
  }
}

function calculateRequestMetrics(requestsPerAction: MetricBucket[]) {
  const requestCounts: MetricViz[] = []
  const statsPerAction = { index: [], search: [] }

  for (const actionBucket of requestsPerAction) {
    const action = actionBucket.key
    const _95ths: MetricViz = {
      name: `95th percentile`,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
    }
    const _99ths: MetricViz = {
      name: `99th percentile`,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
    }
    const averages: MetricViz = {
      name: `Average`,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
    }
    const counts: MetricViz = {
      name: action,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
    }

    for (const timeBucket of actionBucket.aggregations.aggregations.per_interval
      .date_histogram_aggregations_result.buckets) {
      const timestamp = new Date(timeBucket.key)
      const innerTimeBucket = timeBucket.aggregations.aggregations

      for (const trace of [_95ths, _99ths, averages, counts]) {
        trace.x.push(timestamp)
      }

      counts.y.push(innerTimeBucket.total?.sum_aggregations_result?.value || 0)
      _95ths.y.push(innerTimeBucket.p95?.max_aggregations_result?.value || 0)
      _99ths.y.push(innerTimeBucket.p99?.max_aggregations_result?.value || 0)
      averages.y.push(innerTimeBucket.avg?.max_aggregations_result?.value || 0)
    }

    requestCounts.push(counts)
    statsPerAction[action] = [averages, _95ths, _99ths]
  }

  return {
    requestCounts,
    statsPerAction,
  }
}

function calculateMemoryMetrics(gcsPerNode: MetricBucket[]) {
  const majorGCsPerNode: MetricViz[] = []
  const pressurePerNode: MetricViz[] = []
  const diskUsagePerNode: MetricViz[] = []

  for (const nodeBucket of gcsPerNode) {
    const nodeName = nodeBucket.key.replace(/-00+/, `-0`)
    const gcForNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const memoryPressure: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const diskUsage: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }

    for (const timeBucket of nodeBucket.aggregations.aggregations.per_interval
      .date_histogram_aggregations_result.buckets) {
      const timestamp = new Date(timeBucket.key)
      const innerTimeBucket = timeBucket.aggregations.aggregations

      gcForNode.x.push(timestamp)
      gcForNode.y.push(innerTimeBucket.cumulative_gc_time_ms.max_aggregations_result?.value || 0)

      memoryPressure.x.push(timestamp)
      memoryPressure.y.push(
        innerTimeBucket.old_pressure_percent?.sum_aggregations_result?.value || 0,
      )

      diskUsage.x.push(timestamp)
      diskUsage.y.push(innerTimeBucket.disk_used_percent?.sum_aggregations_result?.value || 0)
    }

    majorGCsPerNode.push(gcForNode)
    pressurePerNode.push(memoryPressure)
    diskUsagePerNode.push(diskUsage)
  }

  return {
    majorGCsPerNode,
    pressurePerNode,
    diskUsagePerNode,
  }
}

function reduceMetrics(metrics: MetricsPayload): Metrics {
  const { cpu_metrics, request_metrics, gc_metrics } = metrics

  const cpu = calculateCpuMetrics(
    cpu_metrics?.aggregations?.aggregations.per_node?.terms_aggregations_result?.buckets ?? [],
  )
  const request = calculateRequestMetrics(
    request_metrics?.aggregations?.aggregations.per_action?.terms_aggregations_result.buckets || [],
  )
  const memory = calculateMemoryMetrics(
    gc_metrics?.aggregations?.aggregations.per_node?.terms_aggregations_result?.buckets ?? [],
  )

  return {
    cpu,
    request,
    memory,
  }
}
